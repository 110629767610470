<template>
  <main>
    <package-header title="Edit Application" subTitle="12764 Smith"></package-header>

    <div class="container-fluid">
      <div class="row">
        <div class="col">

          <div class="mb-3 border-bottom pb-3">
            <!-- Back to applications link -->
            <router-link class="mr-2" to="/pages/applications">
              <svg class="icon icon-arrow_cta_back mr-sm-1"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta_back"></use></svg>
              <span class="d-none d-sm-inline-block">Return to Applications</span>
              <span class="d-sm-none">Back</span>
            </router-link>

            <!-- Change instrument dropdown -->
            <a href="javascript:void(0)" class="btn btn-md btn-secondary dropdown-toggle d-sm-inline-block" id="instrument" data-toggle="dropdown" aria-controls="instrument-contents" aria-expanded="false">
              Go To Application
              <span class="caret"></span>
            </a>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" href="javascript:void(0)">01 - Transfer Caso <span class="badge badge-success">E-signed</span></a>
              <a class="dropdown-item font-weight-bold" href="javascript:void(0)">02 - A1 release</a>
              <a class="dropdown-item" href="javascript:void(0)">03 - Mortgage Caso</a>
              <a class="dropdown-item" href="javascript:void(0)">04 - A2 release</a>
              <a class="dropdown-item" href="javascript:void(0)">05 - Application with longer name</a>
            </div>
          </div>

          <!-- Package title and actions -->
          <div class="d-md-flex align-items-start justify-content-between">
            <div>
              <h2 class="page-title mr-2">A1 release</h2>

              <div class="d-flex align-items-center">
                <small>
                  <span class="text-muted text-small">Application Type: </span>
                  <strong>Application Type</strong>
                </small>
                <button class="btn btn-secondary btn-sm ml-2">Change</button>
              </div>

              <ul class="list-inline mb-1">
                <li class="list-inline-item">
                  <small><span class="text-muted text-small">Status:</span> <strong>Drafted</strong></small>
                </li>
                <li class="list-inline-item">
                  <small><span class="text-muted">Modified:</span> <strong>Sep 22, 2018 at 5:33 PM</strong></small>
                </li>
              </ul>
            </div>

            <!-- Drawer toggle and delete package -->
            <div class="d-flex justify-content-between">
              <button class="btn btn-md btn-secondary d-md-none mb-2" type="button" data-toggle="collapse" data-target="#application-nav" aria-expanded="false" aria-controls="application-nav">
                <svg class="icon icon-more"><use xlink:href="/icons/symbol-defs.svg#icon-more"></use></svg>
                <svg class="icon icon-close"><use xlink:href="/icons/symbol-defs.svg#icon-close"></use></svg>
                Navigate Application
              </button>
              <button type="button" class="btn btn-md btn-danger mb-2">
                <svg class="icon icon-delete d-sm-none"><use xlink:href="/icons/symbol-defs.svg#icon-delete"></use></svg>
                <span class="d-none d-sm-block">Delete Application</span>
              </button>
            </div>
          </div>

          <!-- Main package content -->
          <div class="row mt-md-3">

            <!-- Step wizard -->
            <div class="col-md-3 mb-2 mb-md-4">
              <div class="collapse collapse-md" id="application-nav">
                <div class="step-wizard">
                  <div class="step completed">
                    <router-link class="step-link" to="/pages/edit-application/applicant">Applicant</router-link>
                  </div>
                  <div class="step completed">
                    <router-link class="step-link" to="/pages/edit-application/charges-liens-interests">Charge, Lien or Interest</router-link>
                  </div>
                  <div class="step completed">
                    <router-link class="step-link" to="/pages/edit-application/pid-legal-description">Description of Land</router-link>
                  </div>
                  <div class="step completed">
                    <router-link class="step-link" to="#">Part 2 or Informational Document</router-link>
                  </div>
                  <div class="step active">
                    <router-link class="step-link" to="/pages/edit-application/supporting-evidence">Supporting Evidence or Declaration</router-link>
                  </div>
                  <div class="step">
                    <a class="step-link" href="javascript:void(0)">Validation Summary</a>
                  </div>
                  <div class="step">
                    <router-link class="step-link" to="/pages/electronic-instrument">Electronic Instrument</router-link>
                  </div>
                </div>
              </div>
            </div>

            <!-- Data entry form -->
            <div class="col-md-9 col-lg-8">

              <h2 class="mb-3">Declaration</h2>

                <!-- Declaration form -->
              <form>
                <div class="mb-4 p-2 p-md-4 bg-medium">
                  <p>The application may require additional documents or evidence to verify the requirements of registration are met.</p>

                  <div class="form-group">
                    <label class="d-block">Is this a corrective declaration?</label>
                    <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio" id="corrective1" name="corrective1" class="custom-control-input" value="yes" v-model="corrective">
                      <label class="custom-control-label" for="corrective1">Yes</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio" id="corrective2" name="corrective1" class="custom-control-input" value="no" v-model="corrective">
                      <label class="custom-control-label" for="corrective2">No</label>
                    </div>
                  </div>

                  <div class="form-group" v-if="corrective === 'yes'">
                    <label for="correctiveYes">Enter related document number</label>
                    <input type="text" class="form-control col-md-6" name="correctiveYes" id="correctiveYes">
                  </div>

                  <h3 class="mt-4 mb-1">Upload documents</h3>

                  <div class="mb-2">
                    <button class="btn collapse-btn collapsed" data-toggle="collapse" data-target="#help-text" aria-expanded="false" aria-controls="help-text">
                      <svg class="icon icon-arrow_right collapse-icon"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_right"></use></svg>
                      What supporting document do I need to upload?
                    </button>

                    <div id="help-text" class="collapse" aria-labelledby="headingOne" data-parent="#help-text">
                      <div class="collapse-body">
                        Help text
                      </div>
                    </div>
                  </div>

                  <file-upload></file-upload>

                  <!-- Files already uploaded -->
                  <table class="table table-hover table-stacked table-select-row">
                    <tbody>
                      <tr v-for="(item, index) in tableItems">
                        <td class="d-none d-md-table-cell">
                          <span class="td-content"><svg class="icon icon-sequence text-tertiary"><use xlink:href="/icons/symbol-defs.svg#icon-sequence"></use></svg></span>
                        </td>
                        <td data-header="Sequence">
                          <span class="td-content">
                            <input type="text" class="form-control form-control-sm text-center" style="width:4rem" v-bind:value=" index + 1 "></input>
                          </span>
                        </td>
                        <td data-header="Name">
                          <span class="td-content"><a href="#">{{ item.a }}</a></span>
                        </td>
                        <td data-header="Application">
                          <span class="td-content">{{ item.b }}</span>
                        </td>
                        <td data-header="Actions" class="text-right">
                          <span class="td-content"><router-link class="text-danger" to="/pages/electronic-instrument">Delete</router-link></span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="mb-4 p-2 p-md-4 bg-medium">
                  <h3>Enter declaration statement or supporting information</h3>

                  <button class="btn collapse-btn collapsed" data-toggle="collapse" data-target="#help-text2" aria-expanded="false" aria-controls="help-text2">
                    <svg class="icon icon-arrow_right collapse-icon"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_right"></use></svg>
                    More about preparing declaration statements
                  </button>

                  <div id="help-text2" class="collapse" aria-labelledby="headingTwo" data-parent="#help-text2">
                    <div class="collapse-body">
                      Help text
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="statementType">Select statement type</label>
                    <select class="form-control" id="statementType">
                      <option>Enter your own declaration statement</option>
                      <option>Form F statement</option>
                      <option>Election and Authorization</option>
                      <option>Land Tax Deferment Letter Consent</option>
                      <option>Wills, Estate and Succession Act Consent (Distribution of the estate within 120 days)</option>
                      <option>Wills, Estate and Succession Act Consent (Distribution of the estate within 120 days)</option>
                      <option>Wills, Estate and Succession Act Consent (Transfer within 120 days)</option>
                    </select>
                  </div>

                  <div class="form-group">
                    <textarea class="form-control mb-1" name="supportingInformation" id="supportingInformation" rows="4"></textarea>
                  </div>
                </div>
              </form>

              <!-- Action buttons -->
              <div class="d-flex flex-column-reverse flex-sm-row justify-content-sm-between d-print-none">
                <div class="mt-2 mt-sm-0">
                  <button class="btn btn-secondary">Back</button>
                </div>
                <div class="d-flex justify-content-between">
                  <button class="btn btn-secondary mr-1">Save Progress</button>
                  <button class="btn btn-primary">Continue <svg class="icon icon-arrow_cta ml-1"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta"></use></svg></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Scroll to top -->
      <a href="#top" class="d-block float-right scroll-to-top">
        <svg class="icon icon-arrow_upward"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_upward"></use></svg>Back to Top
      </a>
    </div>

    <help-panel title="Applications"></help-panel>
  </main>
</template>

<script>

import PackageHeader from '../../../components/PackageHeader.vue'
import PackageNavigation from '../../../components/PackageNavigation.vue'
import HelpPanel from '../../../components/HelpPanel.vue'
import FileUpload from '../../../components/FileUpload.vue'

export default {

  components: {
    'package-header': PackageHeader,
    'package-navigation': PackageNavigation,
    'help-panel': HelpPanel,
    'file-upload': FileUpload
  },

  data () {
    return {
      corrective: '',
      authorizedSignatureAdded: false,
      tableItems: [
        {
          a:'Patent letter.pdf',
          b:'Mar 10, 2018 5:07 PM'
        },
        {
          a:'document2.pdf',
          b:'Mar 10, 2018 5:07 PM'
        },
        {
          a:'document3.pdf',
          b:'Mar 10, 2018 5:07 PM'
        }
      ]
    }
  },
  methods: {

  }
}
</script>

